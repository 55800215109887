import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import HeadingBg from "@images/slider-img1.jpg"
import PageHeading from "@components/page-heading"
import { Container, Row, Col } from "reactstrap"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const MedBulletin = () => {
  const WhatsNew = [
    {
      cat: "rules",
      data: [
        {
          title: "Current MED Rules",
          date: "1/1/2022",
          link:
            "https://www.sos.state.co.us/CCR/GenerateRulePdf.do?ruleVersionId=9900",
        },
        {
          title: "Emergency Rules",
          date: "8/9/2022",
          link:
            "https://drive.google.com/file/d/1jJL87V_1MRHe__l8k0sMvlQ-Ea43SSLa/view",
        },
      ],
    },
    {
      cat: "recent",
      data: [
        {
          title: "Rulemaking notice",
          date: "8/31/22",
          link:
            "https://sbg.colorado.gov/sites/sbg/files/083122_MED_Rulemaking_Notice.pdf",
        },
        {
          title: "Proposed redlines",
          date: "8/31/22",
          link:
            "https://sbg.colorado.gov/sites/sbg/files/083122_1_CCR_212-3_Proposed_Redlines.pdf",
        },
        {
          title: "SB205 Task Force Interim Meeting Schedule Notice",
          date: "8/16/22",
          link:
            "https://drive.google.com/file/d/1DBoMAOkVPwQZmvUfdVLhNmrgDq-Sr508/view",
        },
        {
          title:
            "Required Patient Documentation for Medical Marijuana Transfers (Sales) Over the Statutory Limits",
          date: "8/15/22",
          link:
            "https://sbg.colorado.gov/sites/sbg/files/220815_Compliance%20Tip_Required_Patient_Documentation.pdf",
        },
        {
          title: "Science & Policy Rulemaking Agenda and Redlines",
          date: "8/9/22",
          link:
            "https://drive.google.com/file/d/1QjzXZJ13XXZeFIA3V-wCkUiuqeHAS07n/view",
        },
        {
          title: "Industry Bulletin 22-04: Preventing Underage Sales",
          date: "8/9/22",
          link:
            "https://sbg.colorado.gov/sites/sbg/files/220809_IB22-04_Underage_Compliance_Check_Bulletin.pdf",
        },
      ],
    },
  ]

  const RulesData = WhatsNew.filter((e) => e.cat === "rules")
  const RecentData = WhatsNew.filter((e) => e.cat === "recent")

  // console.log(WhatsNewData)

  const HelpfulLinks = [
    {
      title: "NEW: MED Data Dashboard",
      link:
        "https://public.tableau.com/app/profile/cu.business.research.division/viz/ColoradoMEDDashboard/Overview",
    },
    {
      title: "2022 Rulemaking Information",
      link: "https://sbg.colorado.gov/med/2022-rulemaking",
    },
    {
      title: "DOR Marijuana Data",
      link: "https://cdor.colorado.gov/data-and-reports/marijuana-data",
    },
    {
      title: "File a Colorado Open Records Act (CORA) Request",
      link: "https://cdor.colorado.gov/cora",
    },
    {
      title: "Have a Question? Submit it via the MED Inquiry Form",
      link:
        "https://docs.google.com/forms/d/e/1FAIpQLSe8i4v_7fchnCAJFHFpOdR4Ki7xefEpJAmy1osMBPvYo9DViQ/viewform",
    },
    {
      title:
        "Local Authorities Accepting Marijuana Business License Applications",
      link:
        "https://sbg.colorado.gov/sites/sbg/files/documents/220824%20Local%20Authority%20Status%20List-Current%20Version%20.pdf",
    },
    {
      title: "MED Fee Schedule",
      link:
        "https://drive.google.com/file/d/1WnyLDcOZ5vNn_hhzzRT86FClzci5_grm/view",
    },
    {
      title: "MED Licensee Information",
      link: "https://sbg.colorado.gov/med-licensee-information",
    },
    {
      title: "Replacement Employee or Owner License (Badge)",
      link:
        "https://sbg.colorado.gov/med-replace-an-employee-or-owner-license-badge",
    },
    {
      title: "Report Compliance Concerns",
      link: "https://sbg.colorado.gov/med-information-referral-form",
    },
  ]

  return (
    <Layout>
      <SEO title="Med Bulletin" description="" />
      <PageHeading heading="Med Bulletin" BgImg={HeadingBg} />
      <div className="page-section">
        <Container>
          <Row>
            <Col lg="6">
              <h2 className="mt-0">What's New</h2>
              <div>
                <h4 className="mt-0 mb-3">Rules</h4>
                {RulesData.map((item) =>
                  item.data.map((i) => (
                    <div>
                      <ul className="list-unstyled mb-0">
                        <li className="d-flex align-items-center">
                          <a
                            href={i.link}
                            target="_blank"
                            rel="noreferrer noopener"
                            className="text-dark"
                          >
                            <p className="my-0 mr-2">{i.title}</p>{" "}
                          </a>
                          <small className=" font-italic">{i.date}</small>{" "}
                        </li>
                      </ul>
                    </div>
                  ))
                )}
              </div>

              <div>
                <h4 className="mb-3">Recently Released</h4>
                {RecentData.map((item) =>
                  item.data.map((i) => (
                    <div>
                      <ul className="list-unstyled mb-0">
                        <li>
                          <a
                            className="text-dark d-flex align-items-center"
                            href={i.link}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <p className="my-0 mr-2">
                              {i.title}{" "}
                              <small className="text-dark font-italic">
                                {i.date}
                              </small>{" "}
                            </p>{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  ))
                )}
              </div>
            </Col>
            <Col lg="6">
              <h2 className="mt-lg-0 mt-5">
                Helpful <span>Links</span>
              </h2>
              <div>
                {HelpfulLinks.map((item) => (
                  <ul className="list-unstyled mb-0">
                    <li>
                      {" "}
                      <a
                        className="text-dark"
                        href={item.link}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {item.title}
                      </a>
                    </li>
                  </ul>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default MedBulletin
